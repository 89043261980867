import React, { useState } from 'react';
import './App.css';

function App() {
  const [formData, setFormData] = useState({
    name: '',
    pronouns: '',
    position: '',
    phone: '',
    location: 'Melbourne',
  });

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupAnimationClass, setPopupAnimationClass] = useState('');

  const correctPassword = 'ResolutionX01'; // Replace with your actual password

  const locations = {
    Melbourne: {
      url: 'https://goo.gl/maps/p5K5pWREEV6kGAb56',
      phone: '(03) 9701 2711',
      tel: '+61 03 9701 2711',
    },
    Sydney: {
      url: 'https://goo.gl/maps/3vhz6ZMoiRFskGsA8',
      phone: '(02) 9737 8060',
      tel: '+61 02 9737 8060',
    },
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (inputPassword === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password, please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const formattedPhone = formatPhoneNumber(value);
      setFormData({
        ...formData,
        [name]: formattedPhone,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const formatPhoneNumber = (phone) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    let formattedPhone = cleaned;

    if (cleaned.length > 2) {
      formattedPhone = cleaned.replace(/(\d{4})(\d{0,3})(\d{0,3})/, (match, p1, p2, p3) => {
        let result = `${p1}`;
        if (p2) result += ` ${p2}`;
        if (p3) result += ` ${p3}`;
        return result;
      });
    }

    return formattedPhone;
  };

  const generateSignature = (type, locations) => {
    const { name, pronouns, position, phone, location } = formData;
  
    const otherLocation = location === 'Melbourne' ? 'Sydney' : 'Melbourne';
  
    let signature = `<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; font-family: Calibri, sans-serif; color: black; font-size: 10pt;">`;
  
    if (type === 'outlook_long' || type === 'webmail' || type === 'source') {
      signature += `<tr><td colspan="2" style="padding: 0 5.4pt; padding-top: 5px;"><p style="margin: 0;"><strong style="font-size: 14pt;">${name}`;
      if (pronouns) {
        signature += ` <span style="font-size: 8pt; font-style: italic; font-weight: normal;">(${pronouns})</span>`;
      }
      signature += `</strong></p></td></tr>`;
  
      signature += `<tr><td colspan="2" style="padding: 0 5.4pt; padding-top: 5px;"><p style="margin: 0;"><strong style="font-size: 10pt; color: #662D91; font-style: italic;">${position}</strong></p></td></tr>`;
  
      signature += `<tr><td colspan="2" style="padding: 5px 5.4pt; padding-top: 5px;"><a href="https://www.resolutionx.com.au/"><img src="https://www.resolutionx.com.au/emailbanner/resx-logo.jpg" alt="Resolution X" width="194" height="30" style="border: none;"></a></td></tr>`;
  
      signature += `<tr>`;
      signature += `<td style="padding: 0 5.4pt; padding-top: 5px;"><img src="https://www.resolutionx.com.au/emailbanner/resx-work.jpg" alt="Location" width="16" height="16" style="border: none; vertical-align: middle;"></td>`;
      signature += `<td style="padding: 0 5.4pt; vertical-align: middle;">`;
      signature += `<a href="${locations[location].url}" target="_blank">${location}</a> & <a href="${locations[otherLocation].url}" target="_blank">${otherLocation}</a>`;
      signature += `</td></tr>`;
  
      signature += `<tr>`;
      signature += `<td style="padding: 0 5.4pt; padding-top: 5px;"><img src="https://www.resolutionx.com.au/emailbanner/resx-phone.jpg" alt="Landline" width="16" height="16" style="border: none; vertical-align: middle;"></td>`;
      signature += `<td style="padding: 0 5.4pt; vertical-align: middle;">${locations[location].tel}</td>`;
      signature += `</tr>`;
  
      const formattedMobile = `+61 ${phone}`;
      signature += `<tr>`;
      signature += `<td style="padding: 0 5.4pt; padding-top: 5px;"><img src="https://www.resolutionx.com.au/emailbanner/resx-mobile.jpg" alt="Mobile" width="16" height="16" style="border: none; vertical-align: middle;"></td>`;
      signature += `<td style="padding: 0 5.4pt; vertical-align: middle;">${formattedMobile}</td>`;
      signature += `</tr>`;
    } else if (type === 'outlook_short') {
      signature += `<tr><td colspan="2" style="padding: 0 5.4pt; padding-top: 5px;"><p style="margin: 0;"><strong style="font-size: 14pt;">${name}`;
      if (pronouns) {
        signature += ` <span style="font-size: 8pt; font-style: italic; font-weight: normal;">(${pronouns})</span>`;
      }
      signature += `</strong></p></td></tr>`;
  
      signature += `<tr><td colspan="2" style="padding: 0 5.4pt; padding-top: 5px;"><p style="margin: 0;"><strong style="font-size: 10pt; color: #662D91; font-style: italic;">${position}</strong></p></td></tr>`;
  
      signature += `<tr><td colspan="2" style="padding: 5px 5.4pt; padding-top: 5px;"><a href="https://www.resolutionx.com.au/"><img src="https://www.resolutionx.com.au/emailbanner/resx-logo.jpg" alt="Resolution X" width="194" height="30" style="border: none;"></a></td></tr>`;
  
      signature += `<tr>`;
      signature += `<td style="padding: 0 5.4pt; padding-top: 5px;"><img src="https://www.resolutionx.com.au/emailbanner/resx-mobile.jpg" alt="Mobile" width="16" height="16" style="border: none; vertical-align: middle;"></td>`;
      signature += `<td style="padding: 0 5.4pt; vertical-align: middle;">${phone}</td>`;
      signature += `</tr>`;
    } else if (type === 'iphone') {
      signature += `<tr><td colspan="2" style="padding: 0 5.4pt; padding-top: 5px;"><p style="margin: 0;"><strong style="font-size: 14pt;">${name}</strong></p></td></tr>`;
  
      signature += `<tr><td colspan="2" style="padding: 0 5.4pt; padding-top: 5px;"><p style="margin: 0;"><strong style="font-size: 10pt; color: #662D91; font-style: italic;">${position}</strong></p></td></tr>`;
  
      signature += `<tr><td colspan="2" style="padding: 5px 5.4pt; padding-top: 5px;"><a href="https://www.resolutionx.com.au/"><img src="https://www.resolutionx.com.au/emailbanner/resx-logo.jpg" alt="Resolution X" width="194" height="30" style="border: none;"></a></td></tr>`;
  
      signature += `<tr>`;
      signature += `<td style="padding: 0 5.4pt; padding-top: 5px;"><img src="https://www.resolutionx.com.au/emailbanner/resx-mobile.jpg" alt="Mobile" width="16" height="16" style="border: none; vertical-align: middle;"></td>`;
      signature += `<td style="padding: 0 5.4pt; vertical-align: middle;">${phone}</td>`;
      signature += `</tr>`;
    }
  
    signature += `</table>`;
  
    return signature;
  };
  

  const copyToClipboard = (id) => {
    const signatureElement = document.getElementById(id);
    const range = document.createRange();
    range.selectNodeContents(signatureElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      alert("Signature copied to clipboard!");
    } catch (err) {
      console.error('Failed to copy text', err);
    }

    selection.removeAllRanges();
  };

  const openPopup = (content) => {
    setPopupContent(content);
    setPopupAnimationClass('open');
    setPopupIsOpen(true);
  };

  const closePopup = () => {
    setPopupAnimationClass('close');
    setTimeout(() => {
      setPopupIsOpen(false);
      setPopupContent('');
      setPopupAnimationClass('');
    }, 300); // Duration should match the CSS animation duration
  };

  return (
    <div className={isAuthenticated ? "container fadeIn" : "login-container"}>
      {!isAuthenticated ? (
        <div className="login-box">
          <img
            src="https://resolutionx.com.au/wp-content/uploads/logo_resolution_x_new_main.png"
            alt="Resolution X Logo"
            style={{ width: '150px', marginBottom: '20px' }}
          />
          <h1>Enter Password</h1>
          <form onSubmit={handlePasswordSubmit} className="login-form">
            <input
              type="password"
              placeholder="Password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              className="password-input"
            />
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      ) : (
        <>
          <img
            src="https://resolutionx.com.au/wp-content/uploads/logo_resolution_x_new_main.png"
            alt="Resolution X Logo"
            style={{ width: '150px', marginBottom: '20px' }}
          />
          <h1>ResX Email Signature Generator</h1>
          <form>
            <label htmlFor="name">Full Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="pronouns">Pronouns (optional):</label>
            <input
              type="text"
              id="pronouns"
              name="pronouns"
              placeholder="e.g., he/him, she/her, they/them"
              value={formData.pronouns}
              onChange={handleChange}
            />

            <label htmlFor="position">Position/Title:</label>
            <input
              type="text"
              id="position"
              name="position"
              value={formData.position}
              onChange={handleChange}
              required
            />

            <label htmlFor="phone">Mobile Number:</label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="e.g., 0412 345 678"
              value={formData.phone}
              onChange={handleChange}
              required
            />

            <label htmlFor="location">Select Primary Location:</label>
            <select
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
            >
              <option value="Melbourne">Melbourne</option>
              <option value="Sydney">Sydney</option>
            </select>
          </form>

          <div className="signature-section">
            <div className="signature-box">
              <h2>Outlook - Long Version</h2>
              <div className="button-group">
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard('outlook_long_signature')}
                >
                  Copy
                </button>
                <button
                  className="modal-button"
                  onClick={() => openPopup('For Outlook:\n1. Copy (using the copy button) the long signature (for new emails) or the short signature (for replies) from the generated signature. \n2. In Outlook go to File > Options then select Mail > Signatures\n3. Click New, call it Resolution X - Long, then paste the long version into the text box. Click save.\n4. Rinse and repeat for the short signature\n5. Change the settings on the right-hand side so New Emails use the \'Long\' signature, and Replies/Forwards use the \'Short\' signature.')}
                >
                  Instructions
                </button>
              </div>
              <div
                id="outlook_long_signature"
                className="signature-content"
                dangerouslySetInnerHTML={{ __html: generateSignature('outlook_long', locations) }}
              />
            </div>

            <div className="signature-box">
              <h2>Outlook - Short Version</h2>
              <div className="button-group">
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard('outlook_short_signature')}
                >
                  Copy
                </button>
                <button
                  className="modal-button"
                  onClick={() => openPopup('For Outlook:\n1. Copy (using the copy button) the short signature from the generated signature.\n2. In Outlook go to File > Options then select Mail > Signatures\n3. Click New, call it Resolution X - Short, then paste the short version into the text box. Click save.\n4. Set this as the default for Replies/Forwards.')}
                >
                  Instructions
                </button>
              </div>
              <div
                id="outlook_short_signature"
                className="signature-content"
                dangerouslySetInnerHTML={{ __html: generateSignature('outlook_short', locations) }}
              />
            </div>

            <div className="signature-box">
              <h2>iPhone Signature</h2>
              <div className="button-group">
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard('iphone_signature')}
                >
                  Copy
                </button>
                <button
                  className="modal-button"
                  onClick={() => openPopup('For iPhone:\n1. Copy the iPhone signature (using the copy button) from the generated signature.\n2. On your iPhone, navigate to Settings > Mail > Signature.\n3. Delete the existing signature, then paste the new signature into the text box.\n4. Shake your iPhone and click Undo when it asks you to Undo change attributes.')}
                >
                  Instructions
                </button>
              </div>
              <p>
                <em>
                  Copy the short version of the signature & paste into iOS (Settings
                  &gt; Mail &gt; Signature), then shake your iPhone and select
                  'Undo Change Attributes' when prompted.
                </em>
              </p>
              <div
                id="iphone_signature"
                className="signature-content"
                dangerouslySetInnerHTML={{ __html: generateSignature('iphone', locations) }}
              />
            </div>

            <div className="signature-box">
              <h2>Webmail Signature</h2>
              <div className="button-group">
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard('webmail_signature')}
                >
                  Copy
                </button>
                <button
                  className="modal-button"
                  onClick={() => openPopup('For Webmail:\n1. Sign in at mail.lightmoves.com\n2. Click on the cog at the top right of the screen, and select Compose\n3. Scroll down to the bottom until you can see Edit Signatures\n4. Delete the existing signature\n5. Click on Source (highlighted below)\n6. Paste all of the blue code from the attached file into the text box \n7. Click on Source again to ensure it looks correct\n8. Click on the blue save button at the top of the screen.')}
                >
                  Instructions
                </button>
              </div>
              <div
                id="webmail_signature"
                className="signature-content"
                dangerouslySetInnerHTML={{ __html: generateSignature('webmail', locations) }}
              />
            </div>
          </div>

          {popupIsOpen && (
            <div className={`popup ${popupAnimationClass}`}>
              <div className="popup-content">
                <h2>Instructions</h2>
                <div>{popupContent.split('\n').map((line, index) => (<p key={index}>{line}</p>))}</div>
                <button onClick={closePopup} className="close-button">Close</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
